import { Route, Routes } from 'react-router-dom';
import './App.css';
import Landing from './Pages/Landing/Landing';
import Layout from './Pages/Layout/Layout';
import Sustainability from './Pages/Sustainability/Sustainability';
import Challenges from './Pages/Challenges/Challenges';
import Ourway from './Pages/Ourway/Ourway';
import Ceroheroinaction from './Pages/Ceroheroinaction/Ceroheroinaction';
import Partners from './Pages/Partners/Partners';
import Rewards from './Pages/Rewards/Rewards';
import Contactus from './Pages/Contactus/Contactus';
import Blogdata from './Pages/Blogdata/Blogdata';
import LetsStartCalculator from './Pages/ImpactCalculations/LetsStartCalculator/LetsStartCalculator';
import CountrySelect from './Pages/ImpactCalculations/CountrySelect/CountrySelect';
import DoYouKnow from './Pages/ImpactCalculations/DoYouKnow/DoYouKnow';
import PersonsCalculator from './Pages/ImpactCalculations/PersonsCalculator/PersonsCalculator';
import ElectricityCalculator from './Pages/ImpactCalculations/ElectricityCalculator/ElectricityCalculator';
import FuelCalculator from './Pages/ImpactCalculations/FuelCalculator/FuelCalculator';
import TravelCalculator from './Pages/ImpactCalculations/TravelCalculator/TravelCalculator';
import TravelCalculator2 from './Pages/ImpactCalculations/TravelCalculator/TravelCalculator2';
import TravelCalculator3 from './Pages/ImpactCalculations/TravelCalculator/TravelCalculator3';
import TravelPublic from './Pages/ImpactCalculations/TravelCalculator/TravelPublic';
import MealsCalculator from './Pages/ImpactCalculations/MealsCalculator/MealsCalculator';
import CarbonFootprint from './Pages/ImpactCalculations/CarbonFootprint/CarbonFootprint';
import Terms from './Pages/Terms/Terms';
import Privacy from './Pages/Privacy/Privacy';
// import MediaPage from './Pages/MediaPage/MediaPage';
import TeamPage from './Pages/TeamPage/TeamPage';
import Advisory from './Pages/Advisory/Advisory';
import Enterprise from './Pages/Enterprise/Enterprise';
import Entry from './Pages/Entry/Entry';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Entry />} />
      <Route path="/home" element={<Layout />}>
        <Route index element={<Landing />} />
        <Route path="landing" element={<Landing />} />
        <Route path="sustainable-learning-hub" element={<Sustainability />} />
        <Route path="Blogdata/:id" element={<Blogdata />} />
        <Route path="challenges" element={<Challenges />} />
        <Route path="about-us" element={<Ourway />} />
        <Route path="cero-hero-community" element={<Ceroheroinaction />} />
        <Route path="partners" element={<Partners />} />
        <Route path="marketplace" element={<Rewards />} />
        <Route path="contactus" element={<Contactus />} />
        <Route path="termsandconditions" element={<Terms />} />
        <Route path="privacypolicy" element={<Privacy />} />
        {/* <Route path="media" element={<MediaPage />} /> */}
        <Route path="advisory" element={<Advisory />} />
        <Route path="team" element={<TeamPage />} />
        <Route path="enterprise" element={<Enterprise />} />
        <Route path="*" element={<Landing />} />
      </Route>  

      <Route path="/personal-carbon-footprint-calculator" element={<LetsStartCalculator />} />
      <Route path="/countryselect" element={<CountrySelect />} />
      <Route path="/doyouknow/:name" element={<DoYouKnow />} />
      <Route path="/household" element={<PersonsCalculator />} />
      <Route path="/electricitycalculation" element={<ElectricityCalculator />} />
      <Route path="/fueluse" element={<FuelCalculator />} />
      <Route path="/travel" element={<TravelCalculator />} />
      <Route path="/travel1" element={<TravelPublic />} />
      <Route path="/travel2" element={<TravelCalculator2 />} />
      <Route path="/travel3" element={<TravelCalculator3 />} />
      <Route path="/meal" element={<MealsCalculator />} />
      <Route path="/carbonfootprint" element={<CarbonFootprint />} />
    </Routes>
  );
}

export default App;



import React from 'react';
import { NavLink } from "react-router-dom";

const Entry = () => {
  return (
    <div className="entrypage-section">
      <div className="entrypage-content">
        <div class="container">
          <div className="row">
            <div className='col-lg-6 col-md-12'>
              <div className="entrycard-body">
                <div className='entrycard'>
                  <p className="entryhighlight"> Sustainability Options for </p>
                  <h2 className="entrycard-title-enterprise">Enterprises</h2>
                  <p className="entrycard-description">
                    We can help businesses of all sizes measure and offset their
                    carbon footprint, plastic waste, shipping/travel emissions, and
                    more!
                  </p>
                </div>
                <div className='discovermore-content'>
                  <img src="../images/entrypage/entryenterprise-img.png" alt="Enterprise" className="entrypage-img" />
                  <NavLink to="/home/contactus">
                    <button className="discoverenterprise-btn">
                      DISCOVER MORE <span><img src="../images/entrypage/entryarrow.png" alt="arw" /></span>
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-12'>
              <div className="entrycard-body">
                <div className='entrycard'>
                  <p className="entryhighlight"> Sustainability Options for </p>
                  <h2 className="entrycard-title-individual">Individuals</h2>
                  <p className="entrycard-description">
                    You can be a leader in the fight against climate change. Offset
                    your carbon footprint and support our industry-leading projects!
                  </p>
                </div>
                <div className="discovermore-content">
                  <img src="../images/entrypage/entryindividual-img.png" alt="Individual" className="entrypage-img" />
                  <NavLink to="/home">
                    <button className="discoverindividual-btn">
                      DISCOVER MORE <span><img src="../images/entrypage/entryarrow.png" alt="arw" /></span>
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div> 
        </div>
      </div>
    </div>
  );
}

export default Entry;
